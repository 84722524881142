import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout.pt";
import SEO from "../../components/seo";
import ContactFormPT from "../../components/contactForm.pt";
import BackgroundImage from "gatsby-background-image";

const ContactUsPagePT = ({ data }) => (
    <Layout>
        <SEO title="Contacte-nos" description="Entre em contacto connosco" lang="pt" />

        <div style={{ position: "relative" }}>
            <BackgroundImage backgroundColor={`#040e18`} fluid={data.heroImage.childImageSharp.fluid} className="brook-breadcaump-area ptb--270 pt_md--190 pb_md--100 pt_sm--190 pb_sm--100 breadcaump-title-bar breadcaump-title-white" data-black-overlay="8" Tag="div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcaump-inner text-center">
                                <h1 className="heading heading-h1 text-white line-height-1 font-80">Contacte-nos</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            <span className="unsplash-cred" style={{ opacity: 1, zIndex: 10 }}>
                Foto de <a href="https://unsplash.com/@dylandgillis?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Dylan Gillis</a> do <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
            </span>
        </div>

        <main className="page-content">
            <div className="contact-modern pb--120 pb_md--80 pb_sm--80">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-12 pr--50 ptb-md--80 ptb-sm--80">
                            <div className="contact-modern bg_color--18 space_dec--100 pt--120 pb--120 pl--60 pr--60">
                                <div className="inner">
                                    <h2 className="heading heading-h2 text-white">Quer iniciar um novo projeto?</h2>

                                    <div className="classNameic-address text-left mt--60">
                                        <h4 className="heading heading-h4 text-white">Visite-Nos</h4>
                                        <div className="desc mt--15">
                                            <p className="bk_pra line-height-2-22 text-white">
                                                <Link href="https://goo.gl/maps/vm3gtyZzC6jQnVNR9" target="_blank" rel="noreferrer">
                                                    Rua Margarida de Abreu N 13, Esc 3
                                                </Link>
                                                <br />N 13, Esc 3, Lisbon Portugal
                                            </p>
                                        </div>
                                    </div>

                                    <div className="classNameic-address text-left mt--60">
                                        <h4 className="heading heading-h4 text-white">Manter contato</h4>
                                        <div className="desc mt--15 mb--30">
                                            <p className="bk_pra line-height-2-22 text-white">
                                                <a href="mailto:info@cottonhat.net">info@cottonhat.net</a> <br /> <a href="tel:00351927344725">(+351) 308 814 283</a>
                                            </p>
                                        </div>
                                        <div className="social-share social--transparent text-white">
                                            <a href="https://www.facebook.com/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Facebook">
                                                <i className="fab fa-facebook"></i>
                                            </a>
                                            <a href="https://www.instagram.com/cottonhat_studio/" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Instagram">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                            <a href="https://www.behance.net/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Behance">
                                                <i className="fab fa-behance"></i>
                                            </a>
                                            <a href="https://www.linkedin.com/company/cottonhat/" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Linkedin">
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                            {/* <a href="https://twitter.com/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Twitter">
                                                <i className="fab fa-twitter"></i>
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 pl--50" style={{ minHeight: 600, marginTop: 100 }}>
                            <ContactFormPT contactUs />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
);

export default ContactUsPagePT;

export const query1 = graphql`
    query {
        heroImage: file(relativePath: { eq: "contact.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    originalImg
                }
            }
        }
    }
`;
